import { dev } from "$app/environment";
import * as Sentry from "@sentry/svelte";

if (!dev) {
	Sentry.init({
		dsn: "https://d6beb8a4fafd4b6affc14422e78bde0b@o4508580172660736.ingest.de.sentry.io/4508595552059472",
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
	});
}
